import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import icon from '../../assests/icons/icon.png';
import { Image } from 'react-bootstrap';

const pages = [
  { name: 'About Us', href: '#about-us' },
  { name: 'Pricing', href: "#pricing" },
  { name: 'Contact info', href: "#contact" }
];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="fixed" sx={{ backgroundImage: 'linear-gradient(to right top, #111, #222, #333, #444, #555)' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
            <Image className="icon" src={icon} alt="icon" style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px'  }}/>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#home"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            SUPERNOVA ENCODER
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              {pages.map((page, index) => (
                <div key={page.name}>
                  <MenuItem onClick={handleCloseNavMenu} href={page.href}>
                    <Typography 
                      component="a"
                      href={page.href}
                      sx={{ 
                        textAlign: 'center',  color: "#505050", textDecoration: 'none',
                        '&:visited': {
                        color: "#505050"
                      }
                      }}>
                        {page.name}
                    </Typography>
                  </MenuItem>
                  {index < pages.length - 1 && <Divider />}
                </div>
              ))}
            </Menu>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
            <Image className="icon" src={icon} alt="icon"  style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px'  }}/>
          </Box>
          <Typography
            component="a"
            href="#home"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            SUPERNOVA ENCODER
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
            {pages.map((page, index) => (
              <div key={page.name} style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    display: 'block',
                    fontWeight: "bold",
                    color: "white",
                    cursor: "pointer",
                    
                    '&:hover': {
                      backgroundColor: "#D3D3D3",
                      color: "#505050"
                    }
                  }}
                  component="a"
                  href={page.href}
                >
                  {page.name}
                </Button>
                {index < pages.length - 1 && <Divider orientation="vertical" flexItem sx={{ bgcolor: 'white', height: '30px', mx: 2, alignSelf: 'center' }} />}
              </div>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;