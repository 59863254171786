import React from 'react';
import { Button, Typography } from '@mui/material';
import icon4 from '../../../assests/icons/icon4.svg';

const Portfolio = () => {
  return (
    <div style={{ textAlign: 'center', padding: '20px'}}>
      <Typography variant="h3" component="h3" xs={{margin: '20px 0', fontWeight: 'bold'}}>Take a look at a Professional Website</Typography>
      <Typography variant="body1" style={{margin: '20px 0'}} sx={{ textAlign: { xs: 'left', sm: 'center' } }}>
        This is a mock example of a professional roofing services website built with Next.js, a React-based framework for building server-rendered, statically generated, and performance optimized websites and applications. 
        It is a great example of how a roofing company can showcase its services and present its brand online. 
        The website is fully responsive, easy to navigate, and has a clean and modern design.
      </Typography>
      <div style={{marginTop: '20px', marginBottom: '20px'}}>
        <Button
          variant="contained"
          startIcon={<img src={icon4} style={{width: '40px'}} alt="Next.js logo"/>}
          onClick={() => window.open("https://contratist-web.vercel.app/", "_blank", "noopener,noreferrer")}
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            color: 'white',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.9)',
            },
          }}
        >
          Access the site
        </Button>
        <span> or preview it below</span>
      </div>
      <div style={{ borderTop: '2px solid #ddd', borderBottom: '2px solid #ddd', padding: '20px' }}>
        <iframe 
          src="https://contratist-web.vercel.app/" 
          style={{ width: '100%', height: '500px', border: 'none', backgroundColor: 'rgba(0, 0, 0, 0.1)' }} 
          title="Professional Roofing Services Website"
        ></iframe>
      </div>
    </div>
  );
};

export default Portfolio;
