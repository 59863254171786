import { Card, CardContent, CardActions, CardHeader, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import './Pricing.css'; // Import the CSS file for custom styles
import { Button } from 'react-bootstrap';

const services = [
  {
    title: "Landing Page",
    price: "$460 USD",
    description: "Perfect for showcasing your business with a professional design.",
    benefits: [
      "Responsive design", "Fast loading speed", "Design included",
      "Cross-browser compatibility", "Mobile-friendly", "Social media links",
      "Tech support up to 30 days (after you can contract maintance)", 
      "Client side rendering", "SSL certificate",
    ],
    monthlyCosts: [
      "Domain: price not included, we set it.", "Hosting: free.",
    ],
  },
  {
    title: "Contact Page",
    price: "$699 USD",
    description: "An effective way to let customers reach you.",
    benefits: [
      "All that include Landing Page", "Contact form view", "About view", "Services view", "Portfolio view",
      "Email notifications up to 3000 monthly included", "Server side rendering", "Whatsapp contact button", "Tech support up to 90 days (after you can contract maintance)",
    ],
    monthlyCosts: [
      "Domain: price not included, we set it up.", "Hosting: $20 USD/month.", "Email notifications: ", "3000 emails free/month,", " 50.000 emails 20 USD/month,", "100.000 emails 90 USD/month",
    ],
  },
  {
    title: "Reservation System",
    price: "$1,690 USD",
    description: "Allow customers to book appointments or reservations online.",
    benefits: [
      "All that include Contact Page", "Booking logic integration", "Availability view", "Database integration", "APIs integration", "Admin view for managing bookings or availability",
      "Advanced security", "Analytics integration", "Authentication with google", "Tech support up to 120 days (after you can contract maintance)",
    ],
    monthlyCosts: [
      "Domain: price not included, we set it up.", "Hosting: $20 USD/month.", "Email notifications: ", "3000 emails free/month,", " 50.000 emails 20 USD/month,", "100.000 emails 90 USD/month",
    ],
  },
  {
    title: "Custom Solutions",
    price: "",
    description: "Tailor-made solutions for online stores, dashboards, and more.",
    benefits: [
      "Full customization", "Scalability", "Mobile APPs for IOS and Android", "Complex logic solutions", "Image cloud integration",
      "Creation of business systems", "Admin management", "Payments integration", "Maintance and support", "And more...",
    ],
    monthlyCosts: [
      "",
    ],
  },
];

export default function Pricing() {
  return (
    <div className="pricing-container">
      <Typography variant="h4" align="center" gutterBottom className="pricing-title">
        Choose the best product starting at just $460 USD
      </Typography>
      <div className="pricing-grid">
        {services.map((service, index) => (
          <Card className="card" key={index} sx={{ padding: 3, boxShadow: 3, borderRadius: 3, transition: "transform 0.3s", "&:hover": { transform: "scale(1.05)" } }}>
            <CardHeader 
              title={service.title} 
              subheader={service.description}
              titleTypographyProps={{ variant: "h6", fontWeight: "bold" }}
            /> 
            <Typography variant="h6" color="primary" align="center">{service.price}</Typography>

            <CardContent>
              <ul className="benefits-list">
                {service.benefits.map((benefit, i) => (
                  <li key={i} className="benefit-item">
                    <CheckCircleIcon color="success" fontSize="small" />
                    <Typography variant="body2">{benefit}</Typography>
                  </li>
                ))}
              </ul>
            </CardContent>
            <Typography variant="body2" color="textSecondary" align="left" className="monthly-costs">
              {service.monthlyCosts.map((cost, i) => (
                <span key={i}>{cost}<br /></span>
              ))}
            </Typography>
            <CardActions>
              <Button variant="primary" href="#contact" style={{ width: "100%", marginTop: "10px" }}>
                Get Started
              </Button>
            </CardActions>
          </Card>
        ))}
      </div>
    </div>
  );
}
